import React from 'react';

const Footer = () => (
    <footer>
        <div className="footer-details">
            <div>
                <h4>Resources</h4>
                <a href=".">Documentation (Soon)</a>
                <a href=".">Support (Soon)</a>
                <a href=".">Community (Soon)</a>
            </div>
            <div>
                <h4>About HeadSwift</h4>
                <a href="https://github.com/Loule95450/HeadSwift" target="_blank">GitHub</a>
                <a href="https://twitter.com/LouleApps" target="_blank">Twitter</a>
            </div>
            <div>
                <h4>Legal</h4>
                <a href="./legal/privacy">Privacy Policy</a>
                <a href="./legal/terms">Terms of Service</a>
            </div>
            <div>
                <h4>Created by</h4>
                <a href="http://github.com/Loule95450"><img src="https://avatars.githubusercontent.com/u/35641311" alt="Loule"/></a>
            </div>
        </div>

        <div className="footer-thanks-you">
            <img src="./images/icons/icon.png" alt="HeadSwift Icon"/>
            <p>Made with <i className ="fas fa-heart"></i> by <a href="https://loule.me/" target="_blank">Loule</a></p>
            <p>Copyright © 2021 HeadSwift. All rights reserved.</p>
        </div>
    </footer>
);

export default Footer;