import React from 'react';

import WelcomeSection from './sections/WelcomeSection';
import SupportSection from './sections/SupportSection';
import WhyUseSection from './sections/WhyUseSection';
import SetupSection from './sections/SetupSection';

const Main = () => (
    <main>
        <WelcomeSection />
        <SupportSection />
        <WhyUseSection />
        <SetupSection />
    </main>
);

export default Main;