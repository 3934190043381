import React from 'react';

const WelcomeSection = () => (
    <section id="Welcome" className="section-left-right">
        <div className="left">
            <h1>Optimize your head tag!</h1>
            <p>Use less line of code while having the same result!</p>
            <a href="#Setup" className="orange-button">Get started</a>
        </div>
        <div className="right">
            <div className="fake-window">
                <div className="fake-window-action-button">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <img src="./images/Welcome-On-Window.png" />
            </div>
        </div>
    </section>
);

export default WelcomeSection;