import React from 'react';

const NavBar = () => (
    <React.Fragment>
        <nav>
            <div className="navbar logo">
                <a href="%PUBLIC_URL%">
                    <img src="images/icons/icon.png" alt="HeadSwift Icon" />
                    <h1>HeadSwift</h1>
                </a>
            </div>
            <div className="navbar nav">
                <a href="#Setup">Setup</a>
                <a href="#WhyUse">Why HeadSwift</a>
                <a href="API">API</a>
            </div>
            <div className="navbar social">
                <a href="https://www.producthunt.com/posts/headswift?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-headswift" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=315056&theme=dark" alt="HeadSwift -  Optimize your head tag!  | Product Hunt" width="200px" /></a>
            </div>
        </nav>
    </React.Fragment>
);

export default NavBar;