import React from 'react';

const WhyUseSection = () => (
    <section id="WhyUse" class="section-top-bottom">
        <div class="top">
            <h1>Why use HeadSwift ?</h1>
        </div>
        <div class="bottom">
            <div>
                <i class="fas fa-tachometer-alt"></i>
                <p>Built for speed</p>
                <p>Less than 30 seconds to generate your perfect head!</p>
            </div>
            <div>
                <i class="fas fa-people-carry"></i>
                <p>Easy to use</p>
                <p>A few clicks to generate your head.</p>
            </div>
            <div>
                <i class="fas fa-money-bill-alt"></i>
                <p>Free</p>
                <p>No hidden fees. No ads.</p>
            </div>
            <div>
                <i class="fas fa-compress-alt"></i>
                <p>Minimize your code</p>
                <p>Save space in your code editor.</p>
            </div>
            <div>
                <i class="fas fa-cogs"></i>
                <p>No configuration</p>
                <p>No configuration required. New frameworks added daily.</p>
            </div>
            <div>
                <i class="fas fa-database"></i>
                <p>Shhhhhh</p>
                <p>No data is taken about you and your users (No one will know who the impostor is ඞ)</p>
            </div>
        </div>
    </section>
);

export default WhyUseSection;