import React from 'react';

class SetupSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            JSCode: '<script> type="application/javascript">function headSwiftData(e){e.forEach(e => document.querySelector("head").innerHTML += e), console.log("👌 HeadSwift Head Loaded")}</script><script> type="application/javascript" src="https://headswift.com/cdn/?head&frameworks=headswift-stats<string className="add-framework-name"></string>&version=0.1.0<string className="add-framework-version"></string>&js"></script>',
            PHPCode: '<?php echo file_get_contents("https://headswift.com/cdn/0.1.0/?frameworks=headswift-stats<string className="add-framework-name"></string>&version=0.1.0<string className="add-framework-version"></string>"); ?>',
            frameworks: ["twitter-bootstrap", "jquery", "font-awesome"]
        }
    }

    render() {
        return (
            <section id="Setup" className="section-top-bottom">
                <div className="top">
                    <h1>Let's start the setup</h1>
                </div>
                <div className="bottom">
                    <section className="section-left-right">
                        <div className="left">
                            <div className="fake-window">
                                <div className="fake-window-action-button">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <section className="section-top-bottom">
                                    <div className="top">
                                        <h1>Your code for PHP</h1>
                                        <p>Copy it and paste it in &lt;head&gt;</p>
                                    </div>
                                    <div className="bottom">
                                        <code>
                                            {this.state.PHPCode}
                                        </code>
                                    </div>
                                </section>
                            </div>

                            <div className="fake-window">
                                <div className="fake-window-action-button">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <section className="section-top-bottom">
                                    <div className="top">
                                        <h1>Your code for JavaScript</h1>
                                        <p>Copy it and paste it in &lt;head&gt;</p>
                                    </div>
                                    <div className="bottom">
                                        <code>
                                            {this.state.JSCode}
                                        </code>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <div className="right">
                            <h1>The most popular</h1>
                            <p>---</p>
                            <div class="framework">
                                {
                                    this.state.frameworks.map((frameworkName, i) => {
                                        return (
                                            <div className="framework">
                                                <div className="framework-name">
                                                    {frameworkName}
                                                </div>
                                                <p class="framework-description">
                                                    {/* {
                                                        fetch("https://api.cdnjs.com/libraries/"+frameworkName).then(res => res.json()).then(
                                                            (result) => { console.log("Hey"); }
                                                          )
                                                    } */}
                                                </p>
                                                <div className="framework-version">
                                                    0.1.0
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
}

export default SetupSection;