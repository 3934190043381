import React from 'react';

const SupportSection = () => (
    <section id="Support" className="section-top-bottom">
        <div className="top">
            <h1>Support for your favorite Frameworks</h1>
            <p>No configuration required. New frameworks added daily.</p>
            <ul>
                <li><img src="https://www.google.com/s2/favicons?sz=64&domain_url=getbootstrap.com" alt="Bootstrap"/>
                </li>
                <li><img src="https://www.google.com/s2/favicons?sz=64&domain_url=jquery.com" alt="jQuery"/></li>
                <li><img src="https://www.google.com/s2/favicons?sz=64&domain_url=tailwindcss.com" alt="TailWindCSS"/>
                </li>
                <li><img src="https://www.google.com/s2/favicons?sz=64&domain_url=bulma.io" alt="Bulma"/></li>
                <li><img src="https://www.google.com/s2/favicons?sz=64&domain_url=materializecss.com" alt="MaterializeCSS"/></li>

            </ul>
        </div>
        <div className="bottom">
            <section className="section-left-right">
                <div className="left">
                    <img src="https://getbootstrap.com/docs/5.1/assets/img/bootstrap-icons.png"/>
                </div>
                <div className="right">
                    <h1>Bootstrap</h1>
                    <p> Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most
                        popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid
                        system, extensive prebuilt components, and powerful JavaScript plugins.</p>
                </div>
            </section>
        </div>
    </section>
);

export default SupportSection;