import React from 'react';

// Import components
import NavBar from './NavBar';
import Footer from './Footer';
import Main from './Main';

// Import CSS
import '../../css/global.style.css';
import '../../css/index.style.css';

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Main />
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
